<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-4 w-auto">
        {{ $route.params.cycle }}
      </h1>
      <div class="w-7/12 mt-5 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" class="mt-20" v-if="loadingData">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div class="px-3" v-if="!loadingData">
      <card class="p-5 mt-4 flex flex-col">
        <div class="w-full flex mb-4">
          <span class="text-sm font-semibold text-romanSilver uppercase">
            Employee :
          </span>
          <span class="text-sm font-semibold ml-1">
            {{ ` ${employee.fname} ${employee.lname}` }}
          </span>
        </div>
        <div class="w-full flex">
          <img
            v-if="employee.photo !== '' && employee.photo !== null"
            class="mr-3 rounded"
            style="width: 135px; height: 128px"
            :src="employee.photo"
            alt="profile"
          />
          <div
            style="height: 138px; width: 138px; border-radius: 5px"
            class="text-blueCrayola border text-center font-black text-lg pt-14 mr-2"
            v-else
          >
            {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
          </div>
          <div class="w-full flex flex-col">
            <div class="w-full flex">
              <card class="flex p-3 mb-4 w-auto mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="icon-suitcase" size="xs" />
                    <span class="ml-1 text-carrotOrange text-xs">
                      Designation
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.userDesignation }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 mb-4 mr-3 w-auto flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="icon-department" size="xs" />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Function
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.employeeFunction }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 w-auto mb-4 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="line-manager" color="#FFFFFF" size="xs" />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Line Manager
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.reportingTo }}
                  </div>
                </div>
              </card>
            </div>
            <div class="w-full flex">
              <card class="flex p-3 mr-3 w-auto mb-4 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon
                      icon-name="icon-user-list"
                      color="#FFFFFF"
                      size="xs"
                    />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Employee ID
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.employeeId }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 mb-4 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="map-pin" size="xms" />
                    <span class="ml-2 text-dome text-xs">
                      Office Location
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.office }}
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </card>
    </div>

    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <div class="px-3" v-if="!loadingData">
        <card class="p-5" style="margin-top: 4px">
          <div
            class="w-full flex flex-row"
            style="margin-top: 20px; flex-grow: 1"
          >
            <div class="w-7/12 h-auto mt-2">
              <h1 class="text-base font-bold">Agreement Details</h1>
            </div>

            <div class="h-full w-auto flex flex-grow">
              <div class="p-2 w-full flex">
                <div class="flex w-full justify-end ml-10 capitalize">
                  <Badge
                    :label="employeeSubmitted"
                    variant="primary"
                    background-color="rgba(19, 181, 106, 0.08)"
                    color="#13B56A"
                    style="padding: 9px; font-size: 14px; font-weight: 600"
                    v-if="employeeSubmitted === 'submitted' && !approvedSubmit"
                  />

                  <Badge
                    label="Approved"
                    variant="primary"
                    background-color="rgba(19, 181, 106, 0.08)"
                    color="#13B56A"
                    style="
                      padding: 9px;
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 1.5;
                      margin-top: -1.5%;
                    "
                    v-if="approvedSubmit"
                  />
                </div>

                <Button
                  class="text-center font-semibold flex p-1 text-base ml-5 -mr-10"
                  width="12rem"
                  height="1rem"
                  @click="commentModal = true"
                >
                  <div class="flex">
                    <span class="text-flame">
                      <Icon
                        icon-name="icon-message-outline"
                        class-name="mr-2 w-5 h-5"
                        size="xsm"
                      />
                    </span>
                    <span class="mr-1">
                      {{ unreadComments }}
                    </span>
                    Comments
                  </div>
                </Button>
              </div>
            </div>
          </div>

          <div style="border-bottom: 1px solid #878e99" />

          <div class="flex flex-col w-full my-4">
            <!----- List of KPA's ---->

            <div class="w-full h-auto flex flex-col">
              <accordion
                class="mt-6"
                v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                :key="kpaNumber"
                show-divider
                @open="handleOpen($event, kpaTypes.id)"
                :is-open="true"
              >
                <template v-slot:title>
                  <span class="font-bold">{{ kpaTypes.name }}</span>
                </template>

                <template v-slot:block>
                  <div
                    class="p-2 w-auto flex gap-2 items-center bg-ghostWhite rounded-sm mr-2"
                  >
                    <span
                      class="font-black text-xs"
                      :class="
                        getKPAWeight(kpaTypes, kpaTypes.id) >
                        getTotalWeight(kpaTypes, kpaTypes.id)
                          ? ''
                          : ''
                      "
                    >
                      {{ getKPAWeight(kpaTypes, kpaTypes.id) }}%
                    </span>
                  </div>
                </template>

                <div class="w-full">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <div class="w-full flex justify-end">
                      <div
                        class="text-flame font-semibold flex mt-3 mb-1 p-2 text-base cursor-pointer"
                        @click="handleSubmit(createNewKPA(kpaTypes))"
                      >
                        <icon icon-name="icon-plus" class="mr-2" size="xsm" />
                        Add New KPI
                      </div>
                    </div>

                    <scroll-container height="1000px">
                      <Table
                        :headers="KPAHeader"
                        :items="kpaTypes.kpas"
                        class="h-full my-2 table"
                        aria-label="absence table"
                        :has-number="false"
                      >
                        <template v-slot:item="{ item }">
                          <div v-if="item.state" class="-ml-3">
                            <div v-if="item.data.state" class="p-2">
                              <div class="flex flex-col">
                                <div
                                  class="cursor-pointer"
                                  v-if="item.data.isLocked || editingLock"
                                  @click="
                                    lockKPA(item.data.isLocked, item.data)
                                  "
                                >
                                  <icon
                                    icon-name="icon-lock"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div
                                  class="cursor-pointer"
                                  v-else
                                  @click="
                                    lockKPA(item.data.isLocked, item.data)
                                  "
                                >
                                  <icon
                                    icon-name="icon-unlock"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div
                                  class="cursor-pointer"
                                  @click="
                                    deleteKPA(
                                      item.index,
                                      kpaTypes,
                                      item.data.kpaId
                                    )
                                  "
                                  v-if="employeeSubmitted !== 'submitted' && !item.data.isLocked"
                                >
                                  <icon
                                    icon-name="icon-trash"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div v-else />
                              </div>
                            </div>
                          </div>

                          <div v-else-if="item.name">
                            <div class="label">
                              <c-text
                                placeholder="--Enter KPI--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-1 w-72"
                                :disabled="
                                  item.data.isLocked ||
                                  editingLock ||
                                  employeeSubmitted === 'submitted'
                                "
                                v-model="item.data.name"
                                v-tooltip.bottom-middle="item.data.name"
                                :rules="inputRules"
                                label="KPI"
                              />
                            </div>
                          </div>

                          <div v-else-if="item.objective">
                            <div class="label">
                              <c-text
                                placeholder="--Enter Objective--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-2 w-72"
                                :disabled="
                                  item.data.isLocked ||
                                  editingLock ||
                                  employeeSubmitted === 'submitted'
                                "
                                v-model="item.data.objective"
                                v-tooltip.bottom-middle="item.data.objective"
                                :rules="inputRules"
                                label="Objective"
                              />
                            </div>
                          </div>

                          <div v-else-if="item.kpis" class="flex">
                            <div
                              class="label flex"
                              v-for="(n, rating) in item.data.kpis"
                              :key="rating"
                            >
                              <ValidationObserver>
                                <c-text
                                  variant="w-full"
                                  :class="`mr-2 -mt-2 w-48`"
                                  :placeholder="`Criteria ${rating}`"
                                  :disabled="
                                    item.data.isLocked ||
                                    editingLock ||
                                    employeeSubmitted === 'submitted'
                                  "
                                  v-model="item.data.kpis[rating].criteria"
                                  v-tooltip.bottom-middle="
                                    item.data.kpis[rating].criteria
                                  "
                                  :rules="inputRules"
                                  :label="`Criteria ${rating} value`"
                                />
                              </ValidationObserver>
                            </div>
                          </div>

                          <div v-else-if="item.target_date">
                            <div class="label w-72">
                              <div
                                class="date-flex -mt-2"
                                :class="
                                  item.data.isLocked ||
                                  editingLock ||
                                  employeeSubmitted === 'submitted'
                                    ? 'bg-disabled'
                                    : ''
                                "
                              >
                                <datepicker
                                  placeholder="--Select Day & Month & Year--"
                                  style="width: 100%; outline: none"
                                  input-class="date-input"
                                  format="yyyy-MMMM-dd"
                                  v-model="item.data.target_date"
                                  v-tooltip.bottom-middle="
                                    item.data.target_date
                                  "
                                  :disabled="item.data.isLocked || editingLock"
                                />
                              </div>
                            </div>
                          </div>

                          <div v-else-if="item.weight">
                            <div class="label">
                              <c-text
                                placeholder="--Enter Weight--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-2 w-24"
                                type="number"
                                min="0"
                                max="100"
                                :rules="weightRules"
                                v-model="item.data.weight"
                                v-tooltip.bottom-middle="item.data.weight"
                                :disabled="
                                  item.data.isLocked ||
                                  editingLock ||
                                  employeeSubmitted === 'submitted'
                                "
                                label="Weight"
                              />
                            </div>
                          </div>
                        </template>
                      </Table>
                    </scroll-container>
                  </ValidationObserver>
                </div>
              </accordion>
            </div>
          </div>
        </card>
      </div>

      <div class="pl-3 flex mt-5" v-if="!loadingData">
        <div class="w-full flex">
          <div class="flex flex-grow">
            <Button
              options
              :option-list="['Submit', 'Share for Review']"
              :disabled="disableBtn"
              :class="disableBtn ? `btn-disabled` : `bg-dynamicBackBtn text-white`"
              @click="handleSubmit(checkSelected($event))"
              v-if="
                employee.reportingTo &&
                employeeSubmitted !== 'submitted' &&
                payload.status !== 'draft'
              "
            >
              <span class="mr-6 ml-6">Submit</span>
            </Button>

            <Button
              :disabled="disableBtn"
              :class="disableBtn ? `btn-disabled` : `bg-dynamicBackBtn text-white`"
              @click="handleSubmit(checkSelected('Share for Review'))"
              v-if="
                employee.reportingTo &&
                employeeSubmitted !== 'submitted' &&
                payload.status === 'draft'
              "
            >
              <span class="mr-6 ml-6">Share for Review</span>
            </Button>

            <Button
              :disabled="disableBtn"
              :class="disableBtn ? `btn-disabled` : `bg-dynamicBackBtn text-white`"
              @click="handleSubmit(checkSelected('Submit'))"
              v-if="!employee.reportingTo && employeeSubmitted !== 'submitted'"
            >
              <span class="mr-6 ml-6">Submit</span>
            </Button>

            <Button
              background-color="none"
              class="ml-4"
              style="color: #321c3b"
              @click="$router.back()"
              v-if="!approvedSubmit"
            >
              Cancel
            </Button>
          </div>
          <div class="flex justify-end mr-5">
            <Button
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
              width="8rem"
              @click="submit('draft')"
              v-if="payload.status === 'draft'"
            >
              Save as Draft
            </Button>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <RightSideBar
      v-if="commentModal"
      @submit="sendComment"
      @close="commentModal = false"
      submit="Save"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <span class="text-darkPurple font-bold text-lg leading-5 p-0"
          >Comments</span
        >
      </template>
      <div class="w-full flex">
        <div class="flex flex-col w-full" style="min-height: 70vh">
          <CommentsBox
            :comments="allComments"
            @markRead="markAsRead($event)"
            :loading="loadComments"
            @deleteComment="deleteComments($event)"
          />
          <div class="-mb-12" style="margin-top: auto">
            <CTextarea
              placeholder="--Message--"
              variant="primary"
              class="mt-2"
              value=""
              :row="8"
              :height="90"
              :col="30"
              :input="null"
              v-model="comment"
            />
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Button from "@/components/Button";
import ScrollContainer from "@/components/ScrollContainer";
import Accordion from "@/components/Accordion";
import RightSideBar from "@/components/RightSideBar";
import CommentsBox from "@/components/commentsBox";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [timeFormatMixin],
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    Card,
    Badge,
    Datepicker,
    CommentsBox,
    CTextarea,
    CText,
    RightSideBar,
    Table,
    Button,
    ScrollContainer,
    Accordion,
  },
  data() {
    return {
      commentModal: false,
      comment: "",
      unreadComments: "",
      allComments: [],
      loadingData: true,
      loadingTable: true,
      loadComments: true,
      managerLock: false,
      approvedAgreement: false,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance Agreements",
          href: "Performance Agreements",
          id: "Performance Agreements",
        },
        {
          disabled: false,
          text: `${this.$route.params.cycle}`,
          href: `${this.$route.params.cycle}`,
          id: `${this.$route.params.cycle}`,
        },
      ],
      KPAHeader: [
        { title: "•", value: "state" },
        { title: "KPA", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
      inputRules: [
        "required",
        {
          name: "data",
          rule: (w) => this.validateEntries(w),
        },
      ],
      functions: [],
      employee: [],
      templateKPATypes: [],
      weightRules: [
        "required",
        {
          name: "weight",
          rule: (w) => this.validateWeight(w),
        },
      ],
      employeeKPI: [],
      editingLock: false,
      employeeSubmitted: "",
      Ratings: [],
      kpaData: [],
      kpaWeights: [],
      validateKPA: [],
      totalWeight: "",
      disableBtn: false,
      allocatedWeight: 0,
      approvedSubmit: false,
      managerView: "",
      reportingTo: null,
      payload: {
        orgId: "",
        templateId: "",
        agreementId: "",
        goalId: "",
        year: "",
        title: "",
        status: "draft",
        created_by: "",
        created_for: "",
        share_for_review: false,
        kpa_types: [],
      },
    };
  },

  methods: {
    getKPAWeight(kpaTypes, kpaTypesId) {
      let sum = 0;

      if (kpaTypes.id === kpaTypesId) {
        kpaTypes.kpas.forEach((kpi) => {
          sum += Number(kpi.weight);
        });

        this.kpaWeights.filter((kpa) => {
          if (kpa.id === kpaTypesId) {
            // eslint-disable-next-line no-param-reassign
            kpa.weight = sum;
          }
          return {};
        });
      }

      return sum || 0;
    },

    getTotalWeight(kpaTypes, kpaTypesId) {
      let sum = 0;
      this.kpaWeights.filter((kpa) => {
        if (kpa.id === kpaTypesId) {
          sum = kpa.totalKpiWeight;
        }
        return {};
      });

      return sum;
    },

    lockKPA(isLocked, data) {
      if (this.managerLock && this.reportingTo === null) {
        // eslint-disable-next-line no-param-reassign
        data.isLocked = !isLocked;
      } else {
        this.$toasted.error(
          "You do not have the privilege to lock or unlock an agreement",
          { duration: 5000 }
        );
      }
    },

    formatCommentDate(date) {
      const newDate = new Date(date);
      return newDate.toDateString();
    },

    getComments(agreementId) {
      this.allComments = [];
      this.loadComments = true;

      this.$_getAgreementComments(agreementId).then((response) => {
        const { comments } = response.data;

        this.loadComments = false;

        const unread = [];

        comments.forEach((comment) => {
          this.allComments.push({
            message: comment.comment,
            time: this.formatCommentTime(comment.date),
            date: this.formatCommentDate(comment.date),
            commentId: comment.id,
            isRead: comment.readStatus,
            receiver: comment.receiver,
            sender: comment.sender,
          });

          if (comment.readStatus === false) {
            unread.push(comment.readStatus);
          }
        });

        this.unreadComments = unread.length === 0 ? "" : unread.length;
      });
    },

    sendComment() {
      const payload = {
        performanceAgreementId: this.$route.params.id,
        senderId: this.$AuthUser.id,
        receiverId: this.$AuthUser.id,
        comment: this.comment,
        sendTo: "admin",
      };

      this.$_postAgreementComments(payload).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.getComments(this.$route.params.id);
        this.comment = "";
      });
    },

    deleteComments(id) {
      this.$_deleteAgreementComments(id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.$route.params.id);
        })
        .then((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    markAsRead(commentId) {
      const payload = {
        commentId,
        readStatus: true,
      };

      this.$_markAsRead(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.$route.params.id);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getAgreements() {
      this.$_getEmployeeAgreement(this.$AuthUser.id).then((response) => {
        this.loading = false;
        const myAgreements = response.data.EmployeePerformanceAgreements;

        myAgreements.filter((aggreement) => {
          if (aggreement.id === this.$route.params.id) {
            this.payload.templateId = aggreement.templateId;
            this.payload.agreementId = this.$route.params.id;
            this.approvedSubmit = aggreement.approveAndSubmit;
            this.employeeSubmitted = aggreement.agreement_status;
            this.payload.status = aggreement.status;
            const agreementKPA = aggreement.employee_agreement_kpa;
            this.payload.created_for = aggreement.created_for;

            this.viewEmployeeDetails(aggreement.created_for);

            if (aggreement.created_for !== aggreement.created_by) {
              this.managerView = true;
            } else {
              this.managerView = false;
            }

            this.$_getOneTemplate(aggreement.templateId).then((result) => {
              this.$_getKPAWeights(aggreement.templateId).then((res) => {
                const kpaData = res.data.data;

                this.kpaWeights = kpaData;

                let sum = 0;

                this.kpaWeights.forEach((kpa) => {
                  sum += kpa.totalKpiWeight;
                });

                this.totalWeight = sum;
              });

              this.templateKPATypes = result.data.PerformanceTemplate.kpa_types;

              this.payload.title =
                result.data.PerformanceTemplate.agreement_title;

              this.templateKPATypes.forEach((kpaType) => {
                // eslint-disable-next-line no-param-reassign
                kpaType.kpas = [];

                agreementKPA.filter((employeeKpi) => {
                  if (employeeKpi.kpaTypeId === kpaType.id) {
                    kpaType.kpas.push({
                      state: true,
                      isLocked: employeeKpi.isLocked,
                      name: employeeKpi.name,
                      objective: employeeKpi.objective,
                      kpaId: employeeKpi.id,
                      kpis: this.getCriteriaData(employeeKpi.kpis_employee),
                      target_date: this.formatEmployeeDate(
                        employeeKpi.targetDate
                      ),
                      weight: employeeKpi.weight,
                    });
                  }
                  return {};
                });
              });
              this.getSettings();
            });
          }
          return {};
        });
      });
    },

    validateWeight(weight) {
      let sum = 0;
      if (weight < 0) {
        return "Value must be greater than or equal to 0";
      }

      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.forEach((kpi) => {
          sum += Number(kpi.weight);
        });
      });

      if (sum > 100) {
        return "Total allocated weight for all KPA's cannot be greater than 100%";
      }

      this.allocatedWeight = sum;

      if (sum < 100) {
        this.disableBtn = true;
      } else {
        this.disableBtn = false;
      }

      return true;
    },

    validateEntries(t) {
      if (t !== "") {
        return true;
      }

      return false;
    },

    handleOpen(state) {
      if (state) {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    },

    formatCommentTime(date) {
      const newDate = new Date(date);
      const hours = newDate.getUTCHours();
      const minutes = newDate.getMinutes();

      return `${1 + ((hours - 1) % 12)}:${minutes
        .toString()
        .padStart(2, "0")} ${hours > 11 ? "PM" : "AM"}`;
    },

    formatEmployeeDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },

    createNewKPA(kpa) {
      const currentIndex = (kpa.kpas.length - 1);
      const arrayObj = kpa.kpas[currentIndex];
      let isEmpty = true;

      if (
        arrayObj.name !== "" &&
        arrayObj.objective !== "" &&
        arrayObj.weight !== ""
      ) {
        // iterate over criteria array
        arrayObj.kpis.forEach((v) => {
          if (Object.keys(v).every((k) => v[k] !== "")) {
            isEmpty = false;
          }
        });
      }

      this.addKPATable(kpa.id, isEmpty);
    },

    addKPATable(kpaId, valid) {
      if (!valid) {
        if (this.allocatedWeight < 100) {
          this.templateKPATypes.forEach((kpaType) => {
            if (kpaType.id === kpaId) {
              const criteriaArr = this.getCriteriaData("");

              if (criteriaArr.length > 0) {
                kpaType.kpas.push({
                  state: true,
                  isLocked: false,
                  name: null,
                  objective: null,
                  kpis: criteriaArr,
                  target_date: "",
                  weight: "",
                });
              }
            }
          });
        } else {
          this.$toasted.error(
            "Total allocated weight for all KPA's cannot be greater than 100%",
            { duration: 5000 }
          );
        }
      }
    },

    deleteKPA(index, kpaTypes, id) {
      if(kpaTypes.kpas.length > 1) {
        if (!this.approvedSubmit) {
          kpaTypes.kpas.splice(index, 1);
          this.$_deleteAgreementKPI(id).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
        });
      }
      }
      else {
        this.$toasted.error('Cannot delete ALL KPIs of a KPA Type, you can edit KPI values instead', {duration: 5000});
      }
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

      this.Ratings = sortedRatings;

      if (kpis) {
        this.Ratings.forEach((rating, index) => {
          criteriaData.push({
            id: kpis[index].id,
            performanceRatingId: rating.id,
            criteria: kpis[index].criteria,
          });
        });
      } else {
        this.Ratings.forEach((rating) => {
          criteriaData.push({
            performanceRatingId: rating.id,
            criteria: ``,
          });
        });
      }

      return criteriaData;
    },

    submit(status) {
      this.kpaData = [];

      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas.map(({ state, ...rest }) => ({
          ...rest,
        }));

        newKPAs.map((kpi) => {
          const kpiData = kpi;
          kpiData.target_date = this.formatDate(kpiData.target_date, "arrange");

          return {};
        });

        const validation = newKPAs.every(
          (item) => item.name || item.objective || item.weight
        );

        if (validation) {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: newKPAs,
          });
        } else {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: [],
          });
        }

        return {};
      });

      this.payload.kpa_types = this.kpaData;
      this.payload.orgId = this.$orgId;
      this.payload.status = status;

      if (!status) {
        this.payload.agreement_status = "shared";
      }

      this.$_createEmployeeAgreement(this.payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.push({
            name: "EssAgrreements",
            query: { activeTab: "My Agreements" },
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    getFunctions() {
      this.functions = [];
      this.$_getFunctionKpis("").then((response) => {
        response.data.functions.forEach((f) => {
          this.functions.push({
            id: f.id,
            name: f.name,
          });
        });
      });
    },

    viewEmployeeDetails(employeeId) {
      const viewEmployee = [];
      const payload = { id: employeeId };
      const employeeObj = [];
      this.loadingData = true;

      this.$_getOneEmployeeAll(payload).then((response) => {
        employeeObj.push({
          id: response.data.id,
          fname: response.data.fname,
          lname: response.data.lname,
          photo: response.data.photo,
          employeeId: response.data.employeeId,
          userDesignation: response.data.userDesignation.name,
          reportingTo: response.data.userReportingTo
            ? `${response.data.userReportingTo.fname} ${response.data.userReportingTo.lname}`
            : null,
          office: response.data.employeeOffice?.name,
        });

        this.functions.filter((f) => {
          if (f.id === response.data.functionId) {
            employeeObj.push({
              employeeFunction: f.name,
            });
          }
          return {};
        });

        viewEmployee.push(
          employeeObj.reduce((r, i) => Object.assign(r, i), {})
        );

        this.employee = Object.assign({}, ...viewEmployee);

        this.loadingData = false;
      });
    },

    checkSelected(value) {
      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas;

        const validation = newKPAs.every(
          (item) => item.name || item.objective || item.weight
        );

        this.validateKPA.push(validation);
      });

      if (this.validateKPA.every((valid) => valid === true)) {
        if (value === "Submit") {
          this.payload.share_for_review = true;
          this.payload.agreement_status = "submitted";
          this.payload.senderId = this.$AuthUser.id;
        } else {
          this.payload.share_for_review = true;
          this.payload.agreement_status = "shared";
        }
        this.submit("active");
      } else {
        this.validateEntries("");
      }
    },

    approveAgreement() {
      const agreementPayload = {
        agreementId: this.$route.params.id,
        approveAndSubmit: true,
      };

      this.$_approveAgreement(agreementPayload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.push({
            name: "EssAgrreements",
            query: { activeTab: "My Agreements" },
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    getUser() {
      this.$_getAOneOrgUserWithOptions(this.$AuthUser.id).then(({ data }) => {
        this.reportingTo = data.user.reportingTo;
      });

      this.$_getOrgAgreementUsers().then((response) => {
        const employeeData = response.data.employees;

        employeeData.filter((data) => {
          if (data.userId === this.$AuthUser.id) {
            this.approvedAgreement = data.approved;
          }
          return {};
        });
      });
    },

    getSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        const settings = response.data.PerformanceSettings;
        this.managerLock = settings[0].managerCanLockAgreement;

        if (this.approvedSubmit) {
          this.editingLock = settings[0].lockSubmittedAgreement;
        }
      });
    },

    getGoals() {
      this.$_getOrganisationGoal("?filter=agreement").then((response) => {
        const goalData = response.data.goals;
        goalData.forEach((goals) => {
          if (goals.goal.year === Number(this.$route.params.year)) {
            this.payload.goalId = goals.goal.id;
            this.payload.year = goals.goal.year;
          }
          return {};
        });
      });
    },
  },

  mounted() {
    this.getAgreements();
    this.getGoals();
    this.getFunctions();
    this.getGoals();
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;
    });
    this.getUser();
    this.getComments(this.$route.params.id);
  },
};
</script>

<style>
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}

.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.label label {
  display: none;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}

.icon-Size {
  width: 200px;
  height: 200px;
}
.illu-text {
  text-align: center;
  font-weight: 400;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.015em;
}
</style>

